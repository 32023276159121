



































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import RequestCanceledSuccessfullyDialogViewModel from
  '@/vue-app/view-models/allianz-dashboard/allianz-actions/pending-requests/request-canceled-successfully-view-model';

@Component({
  name: 'RequestsDialog',
  filters: { currencyFormat },
})
export default class RequestsDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(new RequestCanceledSuccessfullyDialogViewModel());

  accept() {
    this.synced_is_open = false;
  }
}
